import './App.css';
import Navbar from './Components/Navbar/Navbar';
import React, { useEffect } from 'react';
import { HashRouter, BrowserRouter, Route, Routes } from "react-router-dom";
import ContactUs from './Pages/ContactUs/ContactUs';
import AboutUs from './Pages/AboutUs/AboutUs';
import InterestApplication from './Pages/InterestApplication/InterestApplication';
import ForTennants from './Pages/ForTennants/ForTennants';
import OurEstate from './Pages/OurEstate/OurEstate';
import MaintenancePage from './Pages/MaintenancePage/MaintenancePage';
import Personaldata from './Pages/Personaldata/Personaldata';
import Home from './Pages/Home/Home';
import ScrollToTop from './Components/TopScroll/TopScroll';
import Hvitfeldtsgatan44 from './Pages/OurParticularEstate/Hvitfeldtsgatan44';
import Jesperdigsmedsgatan19 from './Pages/OurParticularEstate/Jesperdigsmedsgatan19';
import Skanegatan8 from './Pages/OurParticularEstate/Skanegatan8';
import Hallandsgatan27 from './Pages/OurParticularEstate/Hallandsgatan27';
import Laholmsvagen38 from './Pages/OurParticularEstate/Laholmsvagen38';
import Blekingegatan2 from './Pages/OurParticularEstate/Blekingegatan2';
import Margaretagatan17 from './Pages/OurParticularEstate/Margaretagatan17';
import Margaretagatan10 from './Pages/OurParticularEstate/Margaretagatan10';
import Ljungbyvagen21 from './Pages/OurParticularEstate/Ljungbyvagen21';
import Furuvagen45 from './Pages/OurParticularEstate/Furuvagen45';
import Olympiavagen1 from './Pages/OurParticularEstate/Olympiavagen1';


function App() {


  return (
    <HashRouter>

      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/estate" element={<OurEstate />} />
        <Route path="/estate/hvitfeldtsgatan-44" element={<Hvitfeldtsgatan44 />} />
        <Route path="/estate/jesperdigsmedsgatan-19" element={<Jesperdigsmedsgatan19 />} />
        <Route path="/estate/skanegatan-8" element={<Skanegatan8 />} />
        <Route path="/estate/hallandsgatan-27" element={<Hallandsgatan27 />} />
        <Route path="/estate/laholmsvagen-38" element={<Laholmsvagen38 />} />
        <Route path="/estate/blekingegatan-2" element={<Blekingegatan2 />} />
        <Route path="/estate/margaretagatan-17" element={<Margaretagatan17 />} />
        <Route path="/estate/margaretagatan-10" element={<Margaretagatan10 />} />
        <Route path="/estate/ljungbyvagen-21" element={<Ljungbyvagen21 />} />
        <Route path="/estate/furuvagen-45" element={<Furuvagen45 />} />
        <Route path="/estate/olympiavagen-1" element={<Olympiavagen1 />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/tennant" element={<ForTennants />} />
        <Route path="/personaldata" element={<Personaldata />} />
        <Route exact path="/interestapplication" element={<InterestApplication />} />
        <Route exact path="/maintenance" element={<MaintenancePage />} />



      </Routes>

    </HashRouter>

  );
}

export default App;
