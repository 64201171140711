import React from "react";
import { Link } from "react-router-dom";
import "./Form.css";

function MaintenanceForm() {

    // const descriptionChecker = (maxCharacters) => {

    //     const descriptionInput = document.querySelector(".textArea");
    //     const descriptionMessage = document.getElementById("description-message")
    //     const _maxCharacters = maxCharacters;
    //     const writtenCharacters = _maxCharacters - descriptionInput.value.length;

    //     if (descriptionInput.value.length > 0 && descriptionInput.value.length <= _maxCharacters) {
    //         descriptionMessage.innerHTML = `Characters left ${writtenCharacters} `;
    //         descriptionMessage.classList.remove("text-danger");
    //     }
    //     else if (descriptionInput.value.length > _maxCharacters) {
    //         descriptionMessage.innerHTML = `It must be ${_maxCharacters} character or less (${descriptionInput.value.length})`;
    //         descriptionMessage.classList.add("text-danger");
    //     }
    // }

    // descriptionChecker(1000);

    return (
        <>
            <div className="container FormContainer">
                <div className="col-sm-12 formCol">
                    <div className="formheading">
                        <h5>Felanmälan</h5>
                    </div>
                    <form action="https://formsubmit.co/d4dcb1a501632ba5c2f1e59053e68feb " method="POST" className="form needs-validation">
                        <div className="row">
                            <div className="col-sm-12 inputholder">
                                <input type="text" id="name" name="Namn" className="textInputs" placeholder="Tilltalsnamn" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 inputholder">
                                <input type="email" id="email" name="E-post" className="textInputs" placeholder="E-post" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 inputholder">
                                <input type="text" id="phone" name="Telefonnummer" placeholder="Telefonnummer" className="textInputs" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 inputholder">
                                <input type="text" id="address" name="Adress" placeholder="Adress" className="textInputs" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 inputholder">
                                <input type="text" id="appartmentnumber" name="Lägenhetsnummer" placeholder="Lägenhetsnummer" className="textInputs" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 checkboxcontainer">
                                <h6>Får fastighetsägaren gå in med nyckel?</h6>
                                <select id="animals" name="Får vi gå in med nyckel?" className="Apt-size" required>
                                    <option value="Ja">Ja</option>
                                    <option value="Nej">Nej</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 errordescriptionwrapper">
                                <label for="errordescription"></label>
                                <textarea name="Felbeskrivning" rows="10" cols="80" placeholder="Beskriv felet med max 1000 tecken" className="textArea" required></textarea>
                                <small><span id="description-message" className="text-danger"></span></small>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 submitBtnHolder">
                                <button type="submit" value="Skicka in felanmälan" className="buttons">SKICKA IN FELANMÄLAN</button>
                            </div>
                        </div>
                        <input type="hidden" name="_autoresponse" value="Bekräftelse på mottagen Felanmälan - R.M. Fastighetsförvaltning AB"></input>
                        <input type="text" name="_honey" className="hideform"></input>
                        <input type="hidden" name="_subject" value="Ny Felanmälan"></input>
                        <input type="hidden" name="_next" value="https://www.rmfastighetsforvaltning.se/"></input>

                    </form>
                </div>
            </div>
        </>
    )
}

export default MaintenanceForm;