import React from "react";
import { Link } from "react-router-dom";
import { useIntersectionObserver } from "../Intersectioncomponent/Intersectioncomponent";
import { useRef } from "react";
import "./Interest.css";

function Interest() {
    const interestRef = useRef(null);

    const isVisibleInterest = useIntersectionObserver(interestRef, {
        threshold: 0.5,
    });

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className={`col-sm-12 submitInterest my-div ${isVisibleInterest ? "fade-in" : ""
                        }`}
                        ref={interestRef}>
                        <h1>Intresseanmälan</h1>
                        <p>Söker ni boende hos oss? Fyll i intresseanmälan så återkopplar vi när vi har något ledigt som skulle kunna passa er.</p>
                        <Link to="/interestapplication" className="buttons">Intresseanmälan</Link>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Interest;