import React from "react";
import { Link } from "react-router-dom";
import Maintenance from "../Maintenance/Maintenance";
import Interest from "../Interest/Interest";
import "./Contact.css";

function Contact() {
    return (
        <>
            <div className="container applicationcontainer">
                <div className="row applicationformsrow">
                    <div className="col-sm-12 applicationformswrapper">
                        <Maintenance />
                        <Interest />
                    </div>
                </div>
            </div>
            <div className="container contactCont">
                <div className="row contactInfoImgRow">
                    <div className="col-sm-6 infowrapper">
                        <div className="headingwrapper">
                            <h3>Kontakta oss</h3>
                        </div>
                        <ul>
                            <li className="emailcontainer">
                                <h5>E-post</h5>
                                <a className="emaillink" href="mailto:info@rmfastighetsforvaltning.se">info@rmfastighetsforvaltning.se</a>
                            </li>
                            <li>
                                <h5>Telefon</h5>
                                <p>0709666582 / 0733456073</p>
                            </li>
                            <li>
                                <h5>Öppettider</h5>
                                <p>Mån-Fre 09.00-16.00</p>
                            </li>
                            <li>
                                <h5>Akuta fel</h5>
                                <p>Vid akuta fel utanför kontorstid där det finns risk för att människor eller egendom kommer till skada går det bra att ringa även utanför kontorstid</p>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-6 fasadwrapper">
                        <img src="Img/fasad.jpg" alt="#" className="fasad-img" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;