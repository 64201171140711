import React from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useIntersectionObserver } from "../Intersectioncomponent/Intersectioncomponent";
import "./Maintenance.css";


function Maintenance() {

    const MaintenanceRef = useRef(null);

    const isVisibleInterest = useIntersectionObserver(MaintenanceRef, {
        threshold: 0.5,
    });



    return (
        <>
            <div className="container">
                <div className="row">
                    <div className={`col-sm-12 submitMaintenance my-div ${isVisibleInterest ? "fade-in" : ""
                        }`}
                        ref={MaintenanceRef}>
                        <h1>Felanmälan</h1>
                        <p>Har du som hyresgäst ett fel du vill anmäla? Fyll i formuläret så kontaktar vi dig så fort som möjligt.</p>
                        <Link to="/maintenance" className="buttons">Felanmälan</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Maintenance;