import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Contact from "../../Components/Contact/Contact";
import "../../Components/Footer/Footer.css"
import Footer from "../../Components/Footer/Footer.js"

function ContactUs() {


    return (
        <>
            <Contact />
            <Footer style="bottom=0;" />
        </>
    )
}

export default ContactUs;