import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import About from "../../Components/About/About";
import "./Home.css"
import MiniFooter from "../../Components/MiniFooter/MiniFooter";

function Home() {




    return (
        <>
            <div className="container hero-container">
                <div className="row hero-image">
                    <div className="col-sm-12 hero-text">
                        <p>R.M. Fastighetsförvaltning AB har varit verksamma sedan 2018. Idag äger och förvaltar vi ett 100-tal lägenheter i olika storlekar, samtliga i Halmstad med omnejd.</p>
                        <Link to="/estate" className="homepagebutton">SE VÅRA LÄGENHETER</Link>
                        <Link to="/interestapplication" className="homepagebutton">INTRESSEANMÄLAN</Link>
                    </div>
                </div>
            </div>
            <MiniFooter />
        </>

    )

}

export default Home;