import React, { useEffect } from "react";
import "./Personaldata.css";
import "../../Components/Footer/Footer.css"
import Footer from "../../Components/Footer/Footer.js"
function Personaldata() {


    return (
        <>
            <div className="container personaldatacontainer">
                <div className="col-12 personaldatawrapper">
                    <h3 id="topheading" className="personaldataheading">Personuppgiftspolicy</h3>
                    <h5 className="personaldatasmallheading">Vilka personuppgifter behandlar vi?</h5>
                    <p className="personaldataparagraph">De personuppgifter som vi hanterar är till största delen personuppgifter som du själv
                        lämnar till oss. Vi behandlar endast personuppgifter när vi har laglig grund. De
                        personuppgifter som vi hanterar är t.ex namn, adress, personnummer, e-postadress,
                        telefonnummer, försäkringsinformation, önskemål gällande lägenhet, information kopplad
                        till vår hantering av serviceärenden, information om eventuella tillval av din lägenhet,
                        information om nycklar och passersystem, bokning av tvättstuga och eventuell
                        handikappanpassning.  Vi får även viss information genom din interaktion med oss, t. ex
                        genom felanmälningar eller andra serviceärenden. Vissa personuppgifter får vi från andra
                        källor, t. ex i samband med kreditupplysning som görs när hyreskontrakt tilldelas. Vi
                        behandlar inte personuppgifter i annat fall än när de behövs för att fullgöra förpliktelser
                        enligt avtal och lag.</p>

                </div>
                <div className="col-12 personaldatawrapper">
                    <h5 className="personaldatasmallheading">Vi får också tillgång till dina personuppgifter på följande sätt:</h5>
                    <ul>
                        <li>Uppgifter som du tillhandahåller oss direkt</li>
                        <li>Uppgifter som registreras när du besöker vår hemsida</li>
                        <li>Uppgifter som vi får när du anlitar en av våra medarbetare</li>
                        <li>Uppgifter som vi får när du kontaktar oss, söker anställning hos oss, besöker oss
                            eller på annat sätt tar kontakt med oss</li>
                    </ul>
                </div>

                <div className="col-12 personaldatawrapper">
                    <h5 className="personaldatasmallheading">Så behandlas dina personuppgifter</h5>
                    <p className="personaldataparagraph">Vi utarbetar rutiner och arbetssätt för att dina personuppgifter ska hanteras på ett säkert
                        sätt. Utgångspunkterna är att personal, medarbetare och leverantörer endast får tillgång
                        till dina personuppgifter om det behövs för att de ska kunna utföra sina åtaganden. Alla
                        våra fastighetsprogram, port och entrésystem är utvecklade med din integritet i fokus och
                        skyddar mot intrång, förstöring samt förändringar som kan innebära en risk för din
                        integritet. Alla originalavtal arkiveras inom organisationen. När bekräftelse på avslutat
                        avtal sker, kommer befintliga personuppgifter raderas omgående.
                        Du har när som helst rätt att begära att dina personuppgifter raderas. Vi kommer då inte
                        längre att behandla dina personuppgifter eller inhämta nya, under förutsättning att det
                        inte behövs för att fullgöra våra skyldigheter enligt avtal eller lag. Tänk på att återkallelse
                        av samtycke kan innebära att vi inte kan fullgöra de skyldigheter vi har i förhållande till dig.
                        Som registrerad hos oss har du rätt att få dina personuppgifter korrigerade om de är
                        felaktiga.</p>
                </div>
                <div className="col-12 personaldatawrapper">
                    <h5 className="personaldatasmallheading">När lämnas dina personuppgifter ut?</h5>
                    <p className="personaldataparagraph">R.M. Fastighetsförvaltning AB lämnar enbart ut personuppgifter till tredje part i de fall det
                        är nödvändigt för att uppfylla vårt åtagande som hyresvärd. Det innebär att
                        personuppgifter kan komma att lämnas till försäkringsbolag, inkasso- och
                        kreditupplysningsbolag, jourbolag samt entreprenörer som utför renoveringsarbeten eller
                        skadedjursbekämpning i lägenheten. Uppgifter lämnas även ut till myndigheter i de fall
                        detta krävs enligt lag.

                        R.M. Fastighetsförvaltning AB är personuppgiftsansvarig, vilket innebär att vi är ansvariga
                        för hur dina personuppgifter behandlas.</p>
                </div>

                <div className="col-12 personaldatawrapper">
                    <h3 className="personaldataheading">Säkerhet</h3>
                    <p className="personaldataparagraph">Vi säkerställer att lämpliga tekniska och organisatoriska åtgärder vidtas för att skydda dina
                        personuppgifter mot obehörig åtkomst, förstörelse, olaglig behandling eller oavsiktlig
                        förlust eller skada.
                        Vi använder en säker server där dina personuppgifter behandlas och begränsar tillgången
                        till personuppgifter inom företaget. Tillgång till personuppgifter ges endast till individer
                        inom R.M. Fastighetsförvaltning AB, samt hos Personuppgiftsbiträdet, med det enda syftet
                        att dessa ska kunna utföra sina arbetsuppgifter.</p>
                </div>

                <div className="col-12 personaldatawrapper">
                    <h3 className="personaldataheading">Ändringar i denna sekretesspolicy</h3>
                    <p className="personaldataparagraph">Vi förbehåller oss rätten att ändra denna personuppgiftspolicy efter behov, för att följa
                        ändringar i lagar och förordningar. En sådan förändring kommer att publiceras på vår
                        webbplats.</p>
                </div>



            </div>
            <Footer style="bottom=0;" />
        </>
    )
}

export default Personaldata;