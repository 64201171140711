
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./About.css";
import Interest from "../Interest/Interest";
import { useIntersectionObserver } from "../Intersectioncomponent/Intersectioncomponent";

function About() {
    const aboutRef = useRef(null);
    const galleryRef = useRef(null);
    const interestRef = useRef(null);

    const isVisibleAbout = useIntersectionObserver(aboutRef, {
        threshold: 0.5,
    });

    const isVisibleGallery = useIntersectionObserver(galleryRef, {
        threshold: 0.5,
    });



    return (
        <>
            <div className="container frontpageholder">
                <div className="row frontpagerow">
                    <div className="col-lg-7 infoHolder">
                        <div
                            className={`about-container my-div ${isVisibleAbout ? "fade-in" : ""
                                }`}
                            ref={aboutRef}
                        >
                            <h2 className="aboutus-heading">Om oss</h2>
                            <p>
                                R.M. Fastighetsförvaltning AB är ett privatägt
                                fastighetsbolag som äger och förvaltar fastigheter i
                                centrala Halmstad. Vi vill vara en långsiktig ägare som
                                baserar vår verksamhet på stabilitet och nöjda
                                hyresgäster.
                            </p>

                            <p>
                                Vi har varit verksamma sedan 2018. Idag äger och förvaltar vi ett 100-tal lägenheter i olika storlekar, samtliga i Halmstad med omnejd.
                            </p>

                            <p>
                                Vi är din lokala fastighetsvärd och ledorden vi jobbar efter är "Boende med kvalitet" & "nöjda hyresgäster"
                            </p>


                        </div>

                    </div>
                    <div className="col-lg-5 carouselwrapper">
                        <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src="Img/jesperdigsmedsgatanmain.JPG" className="d-block carouselimage" alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src="Img/skanegatan8main.JPG" className="d-block carouselimage" alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src="Img/Margaretagatan10main.JPG" className="d-block carouselimage" alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src="Img/Hvitfeldstgatanmain.JPG" className="d-block carouselimage" alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src="Img/blekingegatanmain.JPG" className="d-block carouselimage" alt="..." />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row frontpagerow interestholder">
                    <div className="col-sm-12">
                        <Interest />
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;