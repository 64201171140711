import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
    return (
        <div className="container Footer-holder">
            <div className="row footerRow">
                <div className="col-6 col-sm-12 col-md-6 col-lg-3 infocontainer">
                    <div className="RmInfoContainer">
                        <h4 className="RmFooterHeadings" id="footerinfoheading">Kontakta oss</h4>
                        <h6 className="RmContactHeading">Organisationsnummer:</h6>
                        <p className="RmInfo">559168-3670</p>
                        <h6 className="RmContactHeading">Mobil:</h6>
                        <p className="RmInfo">0709 66 65 82 / 0733 45 60 73</p>
                        <h6 className="RmContactHeading">E-post:</h6>
                        <a href="mailto:info@rmfastighetsforvaltning.se" className="RmInfo">info@rmfastighetsforvaltning.se</a>
                    </div>
                </div>
                <div className="col-6 col-sm-12 col-md-6 col-lg-3">
                    <div className="FooterLinks">
                        <h4 className="RmFooterHeadings" id="footerlinksheading">Snabblänkar</h4>
                        <Link to="/contact" className="FooterLink">Kontakt</Link>
                        <Link to="/interestapplication" className="FooterLink">Intresseanmälan</Link>
                        <Link to="/maintenance" className="FooterLink">Felanmälan</Link>
                        <Link to="/personaldata" className="FooterLink">Personuppgiftspolicy</Link>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="FooterRmInfo">
                        <h4 className="RmFooterHeadings" id="Rminfoheading">R.M. Fastighetsförvaltning</h4>
                        <p className="RmInfoText">R.M Fastighetsförvaltning AB äger och förvaltar fastigheter i Halmstad med omnejd. Vi är din lokala fastighetsvärd och ledorden vi jobbar efter är "Boende med kvalitet" & "Nöjda hyresgäster".</p>
                    </div>

                </div>
                <div className="col-6 col-sm-12 col-md-6 col-lg-3 FaceBookIconHolder">
                    <Link to="https://www.facebook.com/profile.php?id=100091885218887"><i className="fa fa-facebook fbicon" aria-hidden="true" href=""></i></Link>
                    <p className="FacebookInfo">Följ oss på Facebook!</p>
                </div>
            </div>

            <div className="row footerbottomRow">
                <div className="col-sm-12 allrightscontainer">
                    <p className="footersmalltext rmallrights">© 2023 R.M. Fastighetsförvaltning AB</p>
                    <p className="footersmalltext">Producerad av: Niklas Andersson</p>
                </div>
            </div>

        </div >
    )
}

export default Footer;