import React, { useEffect } from "react";
import About from "../../Components/About/About";
import "./AboutUs.css";
import "../../Components/Footer/Footer.css"
import Footer from "../../Components/Footer/Footer.js"

function AboutUs() {



    return (
        <>
            <About />
            <Footer style="bottom=0;" />
        </>

    )

}

export default AboutUs;