import React from "react";
import { Link } from "react-router-dom";
import "./Faqaccordion.css";

function Faqaccordion({ index, title, heading, heading2, heading3, heading4, heading5, heading6, heading7, heading8, heading9, heading10, text, text2, text3, text4, text5, text6, text7, text8, text9, text10, cleaningdoc, isOpen, onClick, downloadHref }) {

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = downloadHref;
        link.download = 'cleaning_document.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`heading-${index}`}>
                                    <div>
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse-${index}`}
                                        aria-expanded={isOpen}
                                        aria-controls={`collapse-${index}`}
                                        onClick={() => onClick(index)}
                                    >
                                        {title}
                                    </button>
                                    </div>
                                </h2>
                                <div
                                    id={`collapse-${index}`}
                                    className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
                                    aria-labelledby={`heading-${index}`}
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        <h6>{heading}</h6>
                                        <p className="accordion-paragraph">{text}</p>
                                        {cleaningdoc &&
                                            <div>
                                                <button className="checklistbtn" onClick={handleDownload}>Checklista flyttstäd</button>
                                            </div>
                                        }
                                        <h6>{heading2}</h6>
                                        <p className="accordion-paragraph">{text2}</p>
                                        <h6>{heading3}</h6>
                                        <p className="accordion-paragraph">{text3}</p>
                                        <h6>{heading4}</h6>
                                        <p className="accordion-paragraph">{text4}</p>
                                        <h6>{heading5}</h6>
                                        <p className="accordion-paragraph">{text5}</p>
                                        <h6>{heading6}</h6>
                                        <p className="accordion-paragraph">{text6}</p>
                                        <h6>{heading7}</h6>
                                        <p className="accordion-paragraph">{text7}</p>
                                        <h6>{heading8}</h6>
                                        <p className="accordion-paragraph">{text8}</p>
                                        <h6>{heading9}</h6>
                                        <p className="accordion-paragraph">{text9}</p>
                                        <h6>{heading10}</h6>
                                        <p className="accordion-paragraph">{text10}</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Faqaccordion;