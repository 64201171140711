import React, { useEffect } from "react";
import MaintenanceForm from "../../Components/Form/MaintenanceForm";
import "./MaintenancePage.css";
import Footer from "../../Components/Footer/Footer";


function MaintenancePage() {




    return (
        <>
            <div className="container maintenancecontainer">
                <div className="row application-wrapper">
                    <div className="col-12 maintenancecolumn">
                        <h3>Felanmälan</h3>
                        <p className="errortext">- Felanmälan kan göras dygnet runt i formuläret nedan eller genom att skicka ett mejl till <a href="mailto:felanmalan@rmfastighetsforvaltning.se">felanmalan@rmfastighetsforvaltning.se</a>.
                            Vi registrerar din anmälan nästkommande arbetsdag.
                        </p>
                    </div>
                    <MaintenanceForm />
                </div>

                <div className="row">
                    <div className="col-12 application-wrappers">
                        <p className="urgentmatter"><b>OBS!</b> Vid akuta ärenden eller risk för att människor eller egendom kommer till skada ring,
                            070 966 65 82 / 073 3456 073</p>
                    </div>
                </div>
            </div>
            <Footer style="bottom=0;" />
        </>
    )
}

export default MaintenancePage;