import React, { useEffect } from "react";
import "./OurParticularEstate.css";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer.js";

function Laholmsvagen38() {



    useEffect(() => {
        const googleMapScript = document.createElement("script");
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB6OX4CUPFU5_Ae__ysKZmXvgGvdcCrPLs&callback=initMap`;
        googleMapScript.async = true;
        window.document.body.appendChild(googleMapScript);
    }, []);

    window.initMap = () => {
        const map = new window.google.maps.Map(document.getElementById("google-map"), {
            center: { lat: 56.6645, lng: 12.853 },
            zoom: 12,
        });

        // create markers for each property
        const markers = [
            { position: { lat: 56.668329678323104, lng: 12.873085371290534 }, title: "Laholmsvägen 38" },

        ];

        // create markers on map for each property
        markers.forEach((marker) => {
            const newMarker = new window.google.maps.Marker({
                position: marker.position,
                map: map,
                title: marker.title,
            });

            // add click listener to show info window with property name
            newMarker.addListener("click", () => {
                const infowindow = new window.google.maps.InfoWindow({
                    content: marker.title,
                });
                infowindow.open(map, newMarker);
            });
        });
    };

    window.google = {};

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 specificestatecard">
                        <div className="col-12 specificaddressheading">
                            <h2>Laholmsvägen 38</h2>
                        </div>
                        <div className="row specificestateinfoappartments">
                            <div className="col-12 specificestatebanner">
                                <img src='/Img/Laholmsvagenmain.JPG' alt="Laholmsvägen 38" className="specificestate-img" />
                            </div>
                            <div className="col-12 specificestateinfocontainer">
                                <p>Laholmsvägen 38 är en färgglad byggnad som ligger på populära Nyhem. I närområdet finns både skolor och förskolor samt större livsmedelsbutiker. Det finns totalt 7 lägenheter i byggnaden, 6 lägenheter med två rum och kök och 1 lägenhet med 1 rum och kök. I källaren finns gemensam tvättstuga och förråd. Det finns även privat parkering.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 smallimagescontainer">
                                <div className="col-4 smallimageswrapper">
                                    <img src="/Img/laholmsvagen38small1.JPG" alt="Laholmsvägen 38" className="specificestate-imgsmall" />
                                </div>
                                <div className="col-4 smallimageswrapper">
                                    <img src='/Img/Laholmsvagen38small2.JPG' alt="Laholmsvägen 38" className="specificestate-imgsmall" />
                                </div>
                                <div className="col-4 smallimageswrapper">
                                    <img src='/Img/Laholmsvagen38small3.JPG' alt="Laholmsvägen 38" className="specificestate-imgsmall" />
                                </div>
                            </div>
                        </div>
                        <div className="row specificestateinfoandphoto">
                            <div className="col-12 col-lg-12 specificestatesmallinfocontainer">
                                <div className="specificestatesmallinfowrapper">
                                    <div className="estatenamecontainer col-12">
                                        <p className="specificestatenametitle">Fastighetens Beteckning</p>
                                        <p className="specificsmestatename">Domherren 30</p>
                                    </div>
                                    <div className="col-12 appartmentsizes">
                                        <div>
                                            <p className="specificaptamounttitle">Antal Lägenheter</p>
                                        </div>
                                        <div className="specificaptamountwrapper">
                                            <p className="specificaptamounttext">2:or - 6st</p>
                                            <p className="specificaptamounttext">1:or - 1st</p>
                                        </div>
                                    </div>
                                    <div className="col-12 elevatorcontainer">
                                        <p className="specificelevatortitle">Tillgång till hiss</p>
                                        <p className="specificelevatorno">Nej</p>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className="row googlemaprow">
                            <div className="col-12">
                                <div id="google-map" style={{ height: "500px", width: "100%" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer style="bottom=0;" />
        </>

    );
}

export default Laholmsvagen38;