import React from "react";
import { Link } from "react-router-dom";
import "./Form.css";

function InterestForm() {
    return (
        <>
            <div className="container FormContainer">
                <div className="col-sm-12 formCol">
                    <div className="formheading">
                        <h5>Ansök om boende</h5>
                    </div>
                    <form action="https://formsubmit.co/6f046ecd003d8b22341d499df5f15d07 " method="POST" className="form">
                        <div className="row">
                            <div className="col-sm-12 inputholder">
                                <input type="text" id="name" name="Förnamn" className="textInputs" placeholder="Tilltalsnamn" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 inputholder">
                                <input type="text" id="surname" name="Efternamn" className="textInputs" placeholder="Efternamn" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 inputholder">
                                <input type="text" id="personnummer" name="Personnummer" className="textInputs" placeholder="Personnummer" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 inputholder">
                                <input type="email" id="email" name="E-post adress" className="textInputs" placeholder="E-post" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 inputholder">
                                <input type="text" id="phone" name="Telefonnummer" placeholder="Telefonnummer" className="textInputs" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 checkboxcontainer">
                                <h6>Röker någon i hushållet?</h6>
                                <select id="animals" name="Rökare?" className="Apt-size" required>
                                    <option value="Ja">Ja</option>
                                    <option value="Nej">Nej</option>
                                </select>
                            </div>

                            <div className="col-sm-12 col-md-6 checkboxcontainer">
                                <h6>Har den sökande betalningsanmärkningar?</h6>
                                <select id="animals" name="Betalningsanmärkning?" className="Apt-size" required>
                                    <option value="Ja">Ja</option>
                                    <option value="Nej">Nej</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 checkboxcontainer">
                                <h6>Har ni husdjur?</h6>
                                <select id="animals" name="Husdjur?" className="Apt-size" required>
                                    <option value="Ja">Ja</option>
                                    <option value="Nej">Nej</option>
                                </select>
                            </div>

                            <div className="col-sm-12 col-md-6 optionscontainer">
                                <h6>Önskat inflyttningsdatum</h6>
                                <input type="date" id="date" name="Önskat datum?" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 appartmentsizewrapper">
                                <h6 className="Apt-sizeTitle">Önskad storlek på boende</h6>
                                <select id="appartmentsize" name="Lägenhetsstorlek" className="Apt-size" required>
                                    <option value="1">1 rum</option>
                                    <option value="2">2 rum</option>
                                    <option value="3">3 rum</option>
                                    <option value="4">4 rum</option>
                                    <option value="5">5 rum</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 submitBtnHolder">
                                <button type="submit" value="Skicka intresseanmälan" className="buttons">SKICKA INTRESSEANMÄLAN</button>
                            </div>
                        </div>
                        <input type="hidden" name="_autoresponse" value="Bekräftelse på mottagen intresseanmälan - R.M. Fastighetsförvaltning AB"></input>
                        <input type="text" name="_honey" className="hideform"></input>
                        <input type="hidden" name="_subject" value="Ny Intresseanmälan"></input>
                        <input type="hidden" name="_next" value="https://rmfastighetsforvaltning.se/"></input>
                    </form>
                </div>
            </div>
        </>
    )
}

export default InterestForm;