import React from "react";
import { Link } from "react-router-dom";
import "./MiniFooter.css";

function MiniFooter() {
    return (
        <div className="container MiniFooter-holder">
            <div className="row footerbottomRow">
                <div className="col-12 miniallrightscontainer">
                    <p className="footersmalltext rmallrights">© 2023 R.M. Fastighetsförvaltning AB</p>
                </div>
            </div>
        </div >
    )
}

export default MiniFooter;