import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Estatecomponent from "../../Components/Estatecomponent/Estatecomponent";
import "./OurEstate.css";
import "../../Components/Footer/Footer.css"
import Footer from "../../Components/Footer/Footer.js"




function OurEstate() {




    useEffect(() => {
        const googleMapScript = document.createElement("script");
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB6OX4CUPFU5_Ae__ysKZmXvgGvdcCrPLs&callback=initMap`;
        googleMapScript.async = true;
        window.document.body.appendChild(googleMapScript);
    }, []);

    window.initMap = () => {
        const map = new window.google.maps.Map(document.getElementById("google-map"), {
            center: { lat: 56.6645, lng: 12.853 },
            zoom: 12,
        });

        // create markers for each property
        const markers = [
            { position: { lat: 56.67827500618214, lng: 12.846503098280397 }, title: "Hvitfeldtsgatan 44" },
            { position: { lat: 56.676362444771414, lng: 12.844089598280249 }, title: "Jesperdigsmedsgatan 19" },
            { position: { lat: 56.671274588631064, lng: 12.851925169445016 }, title: "Skånegatan 8" },
            { position: { lat: 56.671416183097904, lng: 12.847211240609886 }, title: "Hallandsgatan 27" },
            { position: { lat: 56.668329678323104, lng: 12.873085371290534 }, title: "Laholmsvägen 38" },
            { position: { lat: 56.66692521417912, lng: 12.872389140609712 }, title: "Margaretagatan 17" },
            { position: { lat: 56.66715540023007, lng: 12.872182069444829 }, title: "Margaretagatan 10" },
            { position: { lat: 56.68493552291213, lng: 12.865064031472064 }, title: "Olympiavägen 1" },
            { position: { lat: 56.67214135471699, lng: 12.845911240609901 }, title: "Blekingegatan 2" },
            { position: { lat: 56.68372046064244, lng: 12.876518240610427 }, title: "Furuvägen 45" },
            { position: { lat: 56.67081287985209, lng: 12.911187425269516 }, title: "Ljungbyvägen 21 A & B" },
            { position: { lat: 56.619939947737485, lng: 12.906156898278029 }, title: "Påarp 112" },
        ];

        // create markers on map for each property
        markers.forEach((marker) => {
            const newMarker = new window.google.maps.Marker({
                position: marker.position,
                map: map,
                title: marker.title,
            });

            // add click listener to show info window with property name
            newMarker.addListener("click", () => {
                const infowindow = new window.google.maps.InfoWindow({
                    content: marker.title,
                });
                infowindow.open(map, newMarker);
            });
        });
    };

    window.google = {};


    return (
        <>
            <div className="container">
                <div className="row estaterow">
                    <div className="col-12 headingwrapper">
                        <h4 className="estateheading">Våra Fastigheter</h4>
                    </div>
                </div>
                <div className="row estaterow">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 estatecolumn">
                        <Link to="/estate/hvitfeldtsgatan-44" className="estatelink">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 estatecard">
                                        <div className="estateinfotitle">
                                            <p>Hvitfeldtsgatan 44</p>
                                        </div>
                                        <div className="row estateinfoappartments">
                                            <div className="col-6 img-holder">
                                                <img src="Img/Hvitfeldstgatanmain.JPG" alt="" className="estate-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 estatecolumn">
                        <Link to="/estate/jesperdigsmedsgatan-19" className="estatelink">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 estatecard">
                                        <div className="estateinfotitle">
                                            <p>Jesperdigsmedsgatan 19</p>
                                        </div>
                                        <div className="row estateinfoappartments">
                                            <div className="col-6 img-holder">
                                                <img src="Img/jesperdigsmedsgatanmain.JPG" alt="" className="estate-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="row estaterow">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 estatecolumn">
                        <Link to="/estate/skanegatan-8" className="estatelink">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 estatecard">
                                        <div className="estateinfotitle">
                                            <p>Skånegatan 8</p>
                                        </div>
                                        <div className="row estateinfoappartments">
                                            <div className="col-6 img-holder">
                                                <img src="Img/skanegatan8main.JPG" alt="" className="estate-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 estatecolumn">
                        <Link to="/estate/hallandsgatan-27" className="estatelink">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 estatecard">
                                        <div className="estateinfotitle">
                                            <p>Hallandsgatan 27</p>
                                        </div>
                                        <div className="row estateinfoappartments">
                                            <div className="col-6 img-holder">
                                                <img src="Img/hallandsgatanmain.JPG" alt="" className="estate-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="row estaterow">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 estatecolumn">
                        <Link to="/estate/laholmsvagen-38" className="estatelink">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 estatecard">
                                        <div className="estateinfotitle">
                                            <p>Laholmsvägen 38</p>
                                        </div>
                                        <div className="row estateinfoappartments">
                                            <div className="col-6 img-holder">
                                                <img src="Img/laholmsvagen1.JPG" alt="" className="estate-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 estatecolumn">
                        <Link to="/estate/blekingegatan-2" className="estatelink">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 estatecard">
                                        <div className="estateinfotitle">
                                            <p>Blekingegatan 2</p>
                                        </div>
                                        <div className="row estateinfoappartments">
                                            <div className="col-6 img-holder">
                                                <img src="Img/blekingegatanmain.JPG" alt="" className="estate-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="row estaterow">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 estatecolumn">
                        <Link to="/estate/margaretagatan-17" className="estatelink">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 estatecard">
                                        <div className="estateinfotitle">
                                            <p>Margaretagatan 17</p>
                                        </div>
                                        <div className="row estateinfoappartments">
                                            <div className="col-6 img-holder">
                                                <img src="Img/margaretagatan17other.JPG" alt="" className="estate-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 estatecolumn">
                        <Link to="/estate/margaretagatan-10" className="estatelink">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 estatecard">
                                        <div className="estateinfotitle">
                                            <p>Margaretagan 10</p>
                                        </div>
                                        <div className="row estateinfoappartments">
                                            <div className="col-12 img-holder">
                                                <img src="Img/Margaretagatan10main.JPG" alt="" className="estate-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="row estaterow">

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 estatecolumn">
                        <Link to="/estate/olympiavagen-1" className="estatelink">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 estatecard">
                                        <div className="estateinfotitle">
                                            <p>Olympiavägen 1</p>
                                        </div>
                                        <div className="row estateinfoappartments">
                                            <div className="col-12 img-holder">
                                                <img src="Img/olympiavagen1.JPG" alt="" className="estate-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 estatecolumn">
                        <Link to="/estate/furuvagen-45" className="estatelink">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 estatecard">
                                        <div className="estateinfotitle">
                                            <p>Furuvägen 45</p>
                                        </div>
                                        <div className="row estateinfoappartments">
                                            <div className="col-12 img-holder">
                                                <img src="Img/furuvagenmain.JPG" alt="" className="estate-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="row estaterow justify-content-center">

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 estatecolumn">
                        <Link to="/estate/ljungbyvagen-21" className="estatelink">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 estatecard">
                                        <div className="estateinfotitle">
                                            <p>Ljungbyvägen 21 A & B</p>
                                        </div>
                                        <div className="row estateinfoappartments">
                                            <div className="col-12 img-holder">
                                                <img src="Img/snostorpmain.JPG" alt="" className="estate-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="row estaterow">
                    <div className="col-12">
                        <div id="google-map" style={{ height: "500px", width: "100%" }}></div>
                    </div>
                </div>
            </div>
            <Footer style="bottom=0;" />
        </>

    )
}

export default OurEstate;


