import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Interest from "../../Components/Interest/Interest";
import Maintenance from "../../Components/Maintenance/Maintenance";
import Faqaccordion from "../../Components/Faqaccordion/Faqaccordion";
import { useState } from "react";
import "./ForTennants.css";
import "../../Components/Footer/Footer.css"
import Footer from "../../Components/Footer/Footer.js"


function ForTennants() {



    const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);

    const handleAccordionClick = (index) => {
        if (openAccordionIndex === index) {
            setOpenAccordionIndex(-1); // close the accordion if it's already open
        } else {
            setOpenAccordionIndex(index);
        }
    };

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 applicationwrapper">
                        <Maintenance />
                        <Interest />
                    </div>
                </div>
            </div>
            <div className="container accordion-container">
                <div className="row accordion-row">
                    <div className="col-12 accordion-wrapper">
                        <h4 className="faqheading">FAQ</h4>
                        <Faqaccordion
                            index={0}
                            title="Ny Hyresgäst"
                            heading="Välkommen!"
                            text="
                            Den dagen du ska flytta in får du tillgång till din lägenhet från kl. 12.00. Om inflyttningsdagen råkar
                            infalla på en lördag, söndag eller helgdag kommer du att få flytta in den första vardagen därefter. Du
                            som bor i en lägenhet ska vara folkbokförd på lägenheten. Lägenhetsnumret är en del av din adress
                            och består av fyra siffror som visar på vilken våning och var på våningen lägenheten finns. Du kan
                            hitta lägenhetsnumret på ditt hyresavtal."
                            heading2="Nycklar"
                            text2="
                            När och hur du hämtar ut din nyckel kommer du och vår personal överens om tillsammans."
                            heading3="Städ och slitage"
                            text3="
                            Det är den tidigare hyresgästen som ansvarar för att lägenheten är i städat skick när du flyttar in.
                            Upplever du att lägenheten är bristfälligt städad eller upptäcker du skador och slitage så behöver du
                            kontakta oss inom sju dagar efter ditt tillträde. Enklast gör du en serviceanmälan via vår hemsida."
                            heading4="Hemförsäkring"
                            text4="
                            Det är viktigt att du tecknar en hemförsäkring innan du flyttar in i din lägenhet. Den skyddar både
                            dig, din lägenhet och dina ägodelar om olyckan skulle vara framme. Om du redan har en
                            hemförsäkring behöver du kontakta ditt försäkringsbolag och upplysa om din ändrade
                            boendesituation"
                            heading5="Adressändring"
                            text5="
                            Snart har du en ny adress. För att din postgång ska fungera är det viktigt att du gör en flyttanmälan i
                            god tid (minst fem arbetsdagar) före flytten."
                            heading6="EL och bredband"
                            text6="
                            Tänk på att du normalt sett behöver teckna elavtal och bredbandsabonnemang. Du behöver då
                            uppge din adress, fastighetsbeteckning samt lägenhetsnummer, detta hittar du på ditt hyreskontrakt.
                            "
                            heading7="Hyra"
                            text7="Som hyresgäst ansvarar du för att din hyra betalas i tid. Hyran ska betalas i förskott varje månad.
                            Förfallodagen är alltid den sista vardagen före den månad som hyran avser. Vårt bankgiro nummer
                            på vilket hyran ska betalas in hittar du både på ditt hyreskontrakt och den hyresavi som skickas ut till
                            dig."
                            heading8="När du behöver hjälp?"
                            text8="Du hittar bra och matnyttig information på vår hemsida, rmfastighetsforvaltning.se. Vårt mål är att
                            du enkelt ska kunna hitta svar på din fråga på hemsidan och slippa kontakta oss på telefon. Skulle du
                            behöva skapa ett serviceärende så är det enklast att du använder dig av formuläret på hemsidan så
                            kontaktar någon dig så fort som möjligt."


                            isOpen={openAccordionIndex === 0}
                            onClick={handleAccordionClick}
                        />
                        <Faqaccordion
                            index={1}
                            title="Trivsel"
                            heading="För allas trivsel"
                            text="
                            Att bo i ett hyreshus med grannar omkring sig ställer krav på respekt och förståelse för varandra. Vi
                            behöver alla hjälpas åt för att skapa trivsel och en trygg känsla där vi bor."
                            heading2="Inne i bostaden"
                            text2="
                            När du bor hos oss förväntar vi oss att du tänker på att hålla ljudnivån nere både i lägenheten och
                            trapphuset, dygnet runt. Att borra och hamra i lägenheten efter kl. 20.00 är inte tillåtet. Har du
                            husdjur behöver du tänka på att inte störa grannarna."
                            heading3="Trapphuset"
                            text3="
                            Trapphuset används av alla och ska vara rent, snyggt och välkomnande. Tänk på att inte ställa till
                            exempel cyklar, barnvagnar och sparkcyklar i trapphuset. Sopor går vi direkt ut med utan att låta de
                            stå i trapphuset. Det är viktigt att trapphuset är fritt från saker som försvårar framkomligheten vid en
                            brand eller en sjuktransport."
                            heading4="Utemiljön"
                            text4="
                            Grönytor och uteplatser ska kunna användas av alla för lek och umgänge. Använd de
                            uppställningsplatser som finns för cyklar, mopeder och bilar. Att vara rädd om buskar och
                            planteringar och att inte lämna skräp efter sig gör att det blir trivsamt för alla."
                            heading5="Gemensamma utrymmen"
                            text5="
                            Källare, vind och förråd blir lätt överfyllda. Håll reda på vad som tillhör dig i de gemensamma
                            utrymmena. Rökning är inte tillåtet i vare sig hiss, trapphus eller i andra gemensamma utrymmen."
                            heading6="Tvättstugan"
                            text6="
                            I tvättstugan finns instruktioner om vad som gäller när du ska tvätta. Städa efter dig så blir det
                            trevligare för nästa hyresgäst. Vi tänker på miljön och därför har man som hyresgäst ett visst antal
                            tvättider varje månad. Tänk också på säkerheten och lämna aldrig barn ensamma i tvättstugan.
                            "
                            isOpen={openAccordionIndex === 1}
                            onClick={handleAccordionClick}
                        />
                        <Faqaccordion
                            index={2}
                            title="Utflyttning"
                            text="Vi tycker att det är tråkigt att du flyttar och hoppas att du har trivts med oss och din bostad. När man
                            flyttar är det mycket som händer och det kan vara svårt att komma ihåg allt som behövs göras.
                            Nedan följer en checklista som vi hoppas kan vara till hjälp. Skulle du inte fullgöra någon av
                            skyldigheterna enligt nedan kommer vi att debitera dig för motsvarande kostnader."
                            heading2="Uppsägningstid"
                            text2="
                            Du måste ha sagt upp din lägenhet senast tre kalendermånader innan utflyttning. Tänk på att i god
                            tid boka för en förbesiktning och en slutbesiktning av din lägenhet."
                            heading3="Gör i ordning lägenheten"
                            text3="
                            Åtgärda eventuella skador och gör en flyttstädning av lägenheten så att en ny hyresgäst kan flytta in
                            och trivas i sitt nya hem. Kom ihåg att om städning inte är väl genomförd eller reparationer inte är
                            fackmannamässigt gjorda så måste vi tyvärr debitera dig. Se våra städtips och checklista."
                            heading4="Lämna nycklar"
                            text4="
                            Alla nycklar som du har till lägenheten ska lämnas tillbaka enligt överenskommelse den dag du flyttar
                            ut eller senast kl 12:00 den dagen ditt kontrakt upphör att gälla. Alla nycklar måste lämnas, även
                            kopior dvs nycklar som har införskaffats av dig som hyresgäst. Annars behöver ett lås byte göras som
                            du som hyresgäst debiteras för."
                            heading5="Packa allt"
                            text5="
                            Packa ned alla dina saker. Töm både din lägenhet och förråd. Flyttlådor finns att köpa i alla
                            byggaffärer och större mataffärer.."
                            heading6="Grovsopor"
                            text6="
                            Stora föremål klassas som grovsopor. Det kan till exempel vara möbler, krukor eller en gammal TV.
                            Dessa lämnar du alltid på kommunens återvinningsstation.
                            "
                            heading7="Adressändring"
                            text7="Gör en flyttanmälan i god tid före flytten. Gå in på adressandring.se. Du behöver även ändra din
                            adress hos Skatteverket så att du får rätt folkbokföringsadress."
                            heading8="Kontakta ditt elbolag"
                            text8="När du anmäler el-abonnemang för din nya lägenhet är det en god idé att avsluta ditt abonnemang
                            på din gamla adress. Du kan kontakta din elleverantör för mer information."
                            heading9="Avsluta autogiro/e-faktura"
                            text9="Om du har e-faktura eller autogiro hos oss, avsluta detta hos din bank."
                            heading10="Hemförsäkring/Abonnemang"
                            text10="Tänk på att flytta din hemförsäkring till ditt nya boende. Glöm inte heller att du också bör flytta din telefon, bredband och tidningsprenumeration."
                            isOpen={openAccordionIndex === 2}
                            onClick={handleAccordionClick}
                        />
                        <Faqaccordion
                            index={3}
                            title="Flyttstädning"
                            text="Använd denna checklista som hjälp vid städning inför slutbesiktningen. Önskas hjälp med att
                            kontakta städfirma, kontakta oss på R.M. Fastighetsförvaltning AB, så kan vi hänvisa er. Vid icke
                            godkända städanmärkningar eller skador, debiteras du som hyresgäst. Lämna checklistan till
                            städentreprenör om du väljer att köpa tjänsten. Var noggrann och lycka till - tack för ett gott
                            samarbete, lämna bostaden så som du önskar finna din nästa."
                            downloadHref="Doc/Cleaninglist.pdf"
                            cleaningdoc={true}
                            isOpen={openAccordionIndex === 3}
                            onClick={handleAccordionClick}
                        />
                    </div>
                </div>
            </div>
            <Footer style="bottom=0;" />
        </>
    );
}

export default ForTennants;