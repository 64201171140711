import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
    return (
        <>
            <div className="container navcontainer">
                <nav className="navbar navbar-expand-lg">
                    <div className="row">
                        <div className="col-11 col-lg-12 headingsloganiconwrapper">
                            <div className="headingcontainer">
                                <h2 className="Rm-heading"><Link to="/" className="Rmheadinglink">R.M. Fastighetsförvaltning AB</Link></h2>
                                <p className="Rm-slogan">-Boende med kvalitet</p>
                            </div>
                        </div>
                        <div className="col-1 iconholder">
                            <div className="iconwrapper">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-8 collapse navbar-collapse linkContainer" id="navbarNav">
                        <div className="links">
                            <ul className="navbar-nav links">
                                <li className="nav-item">
                                    <Link to="/about" className="nav-link navLinks leftnav" aria-current="page">OM OSS</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/estate" className="nav-link navLinks">VÅRA FASTIGHETER</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/tennant" className="nav-link navLinks">FÖR HYRESGÄSTER</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact" className="nav-link navLinks rightnav">KONTAKT</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>








        </>
    );
}


export default Navbar;