import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import InterestForm from "../../Components/Form/InterestForm";
import "./InterestApplication.css";
import "../../Components/Footer/Footer.css"
import Footer from "../../Components/Footer/Footer.js"

function InterestApplication() {



    return (
        <>
            <div className="container interestcontainer">
                <div className="row application-row">
                    <div className="col-12 application-wrappers">
                        <h3 className="interesttitle">Intresseanmälan</h3>
                        <p className="interesttext">Du anmäler ditt intresse för att hyra lägenhet hos oss genom att göra en intresseanmälan i formuläret nedan eller genom att mejla på <a href="mailto:info@rmfastighetsforvaltning.se">info@rmfastighetsforvaltning.se</a>.
                            Din intresseanmälan finns i vår databas för lägenhetssökande i 6 månader, därefter måste du göra en ny intresseanmälan om du fortfarande är intresserad av att hyra lägenhet hos oss.
                        </p>
                    </div>
                </div>
                <div className="row application-row">
                    <div className="col-12 application-list-wrapper">
                        <ul className="application-list">
                            <h5>För att hyra lägenhet hos oss behöver du uppfylla följande kriterier:</h5>
                            <li>Du skall ha svenskt person- eller samordningsnummer.</li>
                            <li>Du skall ha fyllt 18 år.</li>
                            <li>Din inkomst behöver vara hög nog för att betala hyran. Vi utgår från att hyran skall vara en tredjedel av bruttinkomsten enligt konsumentverkets riktlinjer.</li>
                            <li>Vi accepterar all typ av inkomst men premierar inkomst genom förvärvsarbete, egen näringsverksamhet, pension eller studier.</li>
                            <li>Vi godkänner i undantagsfall borgenär.</li>
                        </ul>
                    </div>
                    <InterestForm />
                </div>

                <div className="row application-row">
                    <div className="col-12 application-wrappers smokinginfo">
                        <p className="smokingtext"><b>OBS!</b> Vi tillämpar en rökfri boendemiljö och har därför ett strängt förbud mot rökning i våra fastigheter</p>
                    </div>
                </div>
            </div>
            <Footer style="bottom=0;" />
        </>
    )
}

export default InterestApplication;